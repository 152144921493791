import React from 'react';
import styled from 'styled-components';
import {
  Caption,
  FlexWrapper,
  Small,
  TextWrapper,
} from '@kilornd/modules-wrapper/es/components';

import {
  black,
  blue,
  convertHexToRGB,
  error,
  neutral100,
  white,
} from 'styles/colors';

export const CravingsProgressBar: React.FC = () => (
  <Wrapper>
    <ProgressBar>
      <Label className="current-level">
        <Small fontWeight="bold">
          With <TextWrapper color={blue}>ZeroSmokes®</TextWrapper>
        </Small>
      </Label>
      <Label className="forecasted-level">
        <Small fontWeight={700}>Current level</Small>
      </Label>
      <CurrentProgress />
      <FutureProgress>
        <FutureProgressBubbles />
        <FutureProgressBubbles />
        <FutureProgressBubbles />
      </FutureProgress>
    </ProgressBar>
    <FlexWrapper justifyContent="space-between">
      <Caption>No cravings</Caption>
      <Caption>Strong cravings</Caption>
    </FlexWrapper>
  </Wrapper>
);

const Wrapper = styled.div`
  margin: 2.2rem 0 1rem 0;
`;

const ProgressBar = styled.div`
  position: relative;
  margin-bottom: 0.5rem;
  width: 100%;
  height: 0.375rem;
  background-color: ${neutral100};
  border-radius: 0.5rem;
`;

const CurrentProgress = styled.div`
  position: absolute;
  top: 0rem;
  right: 7%;
  width: 20%;
  height: 0.375rem;
  background: ${error};
  border-radius: 0.5rem;
`;

const FutureProgress = styled.div`
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: 0rem;
  left: 7%;
  width: 20%;
  height: 0.375rem;
  border-radius: 0.5rem;
`;

const FutureProgressBubbles = styled.div`
  width: 0.7rem;
  height: 0.375rem;
  border-radius: 1rem;
  background-color: ${blue};

  :nth-child(2) {
    width: 1rem;
  }
`;

const Label = styled.div<{
  currentProgress?: string;
}>`
  position: absolute;
  padding: 0.125rem 0.375rem;
  background-color: ${white};
  border-radius: 0.5rem;
  box-shadow: 0 0.25rem 0.5rem ${convertHexToRGB(black, 0.16)};
  top: -2.2rem;

  ::after {
    content: '';
    position: absolute;
    bottom: -0.5rem;
    left: 42%;
    border-left: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
    border-top: 0.5rem solid ${white};
  }

  &&.current-level {
    position: absolute;
    left: 0;
  }

  &&.forecasted-level {
    right: 2%;
  }
`;
