import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Link } from 'gatsby';

import {
  Box,
  Caption,
  ContainerSmall,
  ContentFrameSmall,
  GridWrapper,
  H4,
  H5,
} from '@kilornd/modules-wrapper/es/components';
import { useQuery } from '@kilornd/modules-wrapper/es/styles/breakpoints';

import { PrimaryButton, SectionWrapper, Svg } from 'components';
import { neutral200, white } from 'styles/colors';

import { expensesCalculationSelector } from 'state/user/selectors';

export const StopBurningMoney: React.FC = () => {
  const { isTablet } = useQuery();
  const expenses = useSelector(expensesCalculationSelector);

  return (
    <SectionWrapper>
      <ContainerSmall>
        <H5 margin="0 0 1rem" textAlign={!isTablet && 'center'}>
          STOP BURNING YOUR MONEY
        </H5>
        <ContentFrameSmallStyled>
          <Caption textAlign="center">Based on your smoking habit,</Caption>
          <H4 margin="0 0 1rem" textAlign="center">
            you can save
          </H4>
          <GridWrapper alignItems="flex-end" columns={3} gridGap="0.5rem">
            <BoxStyled>
              <MoneyIcon src="save_each_month" />
              <Caption fontWeight={700}>{expenses?.monthly}</Caption>
              <Caption>each month</Caption>
            </BoxStyled>
            <BoxStyled hasBorders>
              <MoneyIcon src="save_in_year" />
              <Caption fontWeight={700}>{expenses?.yearly}</Caption>
              <Caption>in one year</Caption>
            </BoxStyled>
            <BoxStyled>
              <MoneyIcon src="save_in_five_years" />
              <Caption fontWeight={700}>{expenses?.five_years}</Caption>
              <Caption>in 5 years</Caption>
            </BoxStyled>
          </GridWrapper>
        </ContentFrameSmallStyled>
        <Link to="/email">
          <PrimaryButton
            id="summary_stop_burning_money_button"
            data-testid="summary_stop_burning_money_button"
            margin="1.5rem auto"
            maxWidth="22rem"
          >
            Start now
          </PrimaryButton>
        </Link>
      </ContainerSmall>
    </SectionWrapper>
  );
};

const ContentFrameSmallStyled = styled(ContentFrameSmall).attrs({
  padding: '1.5rem',
  background: `${white}`,
  borderRadius: '1rem',
  boxShadow: '0 0.5rem 1.5rem rgba(0, 0, 0, 0.04)',
})``;

const BoxStyled = styled(Box).attrs({
  textAlign: 'center',
})<{
  hasBorders?: boolean;
}>`
  border-left: ${({ hasBorders }) =>
    hasBorders ? `0.063rem solid ${neutral200}` : 'none'};
  border-right: ${({ hasBorders }) =>
    hasBorders ? `0.063rem solid ${neutral200}` : 'none'};
`;

const MoneyIcon = styled(Svg).attrs({ margin: '0 0 1rem' })``;
