import React from 'react';

import {
  Box,
  ContainerSmall,
  FlexWrapper,
  GridWrapper,
  H4,
  Small,
} from '@kilornd/modules-wrapper/es/components';
import { useQuery } from '@kilornd/modules-wrapper/es/styles/breakpoints';

import { SectionWrapper, Svg } from 'components';

const ZEROSMOKES_PROS = [
  {
    id: 1,
    text: (
      <>
        Easy-to-follow program to <b>quit in a natural, easy way</b>
      </>
    ),
  },
  {
    id: 2,
    text: (
      <>
        Created by <b>cognitive behavioral therapy experts</b>
      </>
    ),
  },
  {
    id: 3,
    text: (
      <>
        <b>Personalized</b> to your triggers and cravings
      </>
    ),
  },
  {
    id: 4,
    text: (
      <>
        Suited for <b>all types of smokers</b>
      </>
    ),
  },
  {
    id: 5,
    text: (
      <>
        Step-by-step plan on <b>eliminating withdrawals and discomfort</b>
      </>
    ),
  },
  {
    id: 6,
    text: (
      <>
        <b>Supportive and motivating approach</b> that will make quitting easier
      </>
    ),
  },
];

export const ZeroSmokesPros: React.FC = () => {
  const { isTablet } = useQuery();

  return (
    <SectionWrapper>
      <ContainerSmall padding="0 1rem">
        <H4
          margin={isTablet ? '0 0 1rem' : '0 0 2rem'}
          textAlign={!isTablet && 'center'}
        >
          How is ZeroSmokes different from other programs?
        </H4>
        <GridWrapper
          columns={isTablet ? 1 : 2}
          gridGap="1rem 2rem"
          margin={isTablet ? '0 0 2.5rem' : '0 0 4rem'}
        >
          {ZEROSMOKES_PROS.map(({ id, text }) => (
            <FlexWrapper key={id} alignItems="flex-start">
              <Box maxWidth="1.5rem" margin="0 1rem 0 0">
                <Svg width="1.5rem" height="1.5rem" src="check" />
              </Box>
              <Small>{text}</Small>
            </FlexWrapper>
          ))}
        </GridWrapper>
      </ContainerSmall>
    </SectionWrapper>
  );
};
