import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import {
  Box,
  Caption,
  ContainerSmall,
  ContentFrameSmall,
  FlexWrapper,
  GridWrapper,
  H5,
  Regular,
  TextWrapper,
} from '@kilornd/modules-wrapper/es/components';
import { useQuery } from '@kilornd/modules-wrapper/es/styles/breakpoints';

import { SectionWrapper, Svg } from 'components';
import { blue, neutral200 } from 'styles/colors';

import { quizAnswersSelector } from 'state/user/selectors';

const HEALING_INFO = [
  {
    id: 1,
    icon: 'pulse',
    title: (
      <>
        After <TextWrapper color={blue}>20</TextWrapper> minutes
      </>
    ),
    subtitle: 'Pulse returns to normal',
  },
  {
    id: 2,
    icon: 'smell',
    title: (
      <>
        After <TextWrapper color={blue}>48</TextWrapper> hours
      </>
    ),
    subtitle: 'Your sense of smell and taste improve',
  },
  {
    id: 3,
    icon: 'lungs',
    title: (
      <>
        After <TextWrapper color={blue}>72</TextWrapper> hours
      </>
    ),
    subtitle: 'Breathing becomes much easier',
  },
  {
    id: 4,
    icon: 'skin',
    title: (
      <>
        After <TextWrapper color={blue}>1</TextWrapper> month
      </>
    ),
    subtitle: 'Skin appearance starts to improve',
    gender: 'female',
  },
  {
    id: 5,
    icon: 'erectile',
    title: (
      <>
        After <TextWrapper color={blue}>1</TextWrapper> month
      </>
    ),
    subtitle: 'Blood flow and erectile function improve',
    gender: 'male',
  },
];

export const StartHealing: React.FC = () => {
  const { isTablet } = useQuery();
  const answers = useSelector(quizAnswersSelector);

  return (
    <SectionWrapper>
      <ContainerSmall>
        <H5 margin="0 0 1rem" textAlign={!isTablet && 'center'}>
          START HEALING IMMEDIATELY AFTER YOUR LAST SMOKE
        </H5>
        <ContentFrameSmall>
          <GridWrapper columns={1} gridGap="0" margin="0 1rem">
            {HEALING_INFO.map(({ id, icon, title, subtitle, gender }) => {
              if (gender === answers.gender || !gender) {
                return (
                  <BoxStyled key={id}>
                    <Bullet src="bullet" />
                    <FlexWrapper alignItems="center">
                      <Svg src={icon} />
                      <Box margin="0 0 0 1rem">
                        <Regular fontWeight={700}>{title}</Regular>
                        <Caption>{subtitle}</Caption>
                      </Box>
                    </FlexWrapper>
                  </BoxStyled>
                );
              }
            })}
          </GridWrapper>
        </ContentFrameSmall>
      </ContainerSmall>
    </SectionWrapper>
  );
};

const BoxStyled = styled(Box).attrs({ padding: '0 0 0 0.5rem' })`
  position: relative;
  border-left: 0.125rem solid ${neutral200};
`;

const Bullet = styled(Svg)`
  position: absolute;
  left: -0.5rem;
  top: 1.25rem;
`;
