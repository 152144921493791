import React from 'react';

import {
  Box,
  ContentFrameSmall,
  FlexWrapper,
  GridWrapper,
  H5,
  Small,
} from '@kilornd/modules-wrapper/es/components';
import { useQuery } from '@kilornd/modules-wrapper/es/styles/breakpoints';

import { SectionWrapper, Svg } from 'components';

const ASSISTANT_LIST = [
  {
    id: 1,
    icon: 'local_library',
    text: (
      <>
        <b>Personalized program</b> with over 600 tips and methods to beat
        cravings and triggers
      </>
    ),
  },
  {
    id: 2,
    icon: 'list',
    text: (
      <>
        <b>Daily motivation</b> to stay on track and not fall back to your old
        patterns
      </>
    ),
  },
  {
    id: 3,
    icon: 'people',
    text: (
      <>
        <b>Welcoming community</b> of people who have gone through the same
        process and can support your journey
      </>
    ),
  },
];

export const PersonalAssistant: React.FC = () => {
  const { isTablet } = useQuery();

  return (
    <SectionWrapper>
      <ContentFrameSmall padding="0 1rem">
        <H5 margin="0 0 1rem" textAlign={!isTablet && 'center'}>
          YOUR PERSONAL QUITTING ASSISTANT IS READY TO SUPPORT YOU
        </H5>
        <GridWrapper
          columns={1}
          gridGap="1rem"
          margin={isTablet ? '0 0 2.5rem' : '0 0 4rem'}
        >
          {ASSISTANT_LIST.map(({ id, icon, text }) => (
            <FlexWrapper key={id} alignItems="center">
              <Box maxWidth="1.5rem" margin="0 1rem 0 0">
                <Svg width="1.5rem" height="1.5rem" src={icon} />
              </Box>
              <Small>{text}</Small>
            </FlexWrapper>
          ))}
        </GridWrapper>
      </ContentFrameSmall>
    </SectionWrapper>
  );
};
