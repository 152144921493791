import React from 'react';

import { useRouter } from 'apis/history';

import { NavigationButton } from 'layouts/navigations/NavigationButton';

import { Header } from './sections/Header';
import { StartHealing } from './sections/StartHealing';
import { PersonalSummary } from './sections/PersonalSummary';

import { Author } from './sections/Author';
import { MediaProof } from './sections/MediaProof';
import { StopBurningMoney } from './sections/StopBurningMoney';
import { Methods } from './sections/Methods';
import { WithoutSideEffects } from './sections/WithoutSideEffects';
import { HowDoesItWork } from './sections/HowDoesItWork';
import { PersonalAssistant } from './sections/PersonalAssistant';
import { ZeroSmokesPros } from './sections/ZeroSmokesPros';

const Summary: React.FC = () => {
  const { goToEmail } = useRouter();

  return (
    <>
      <NavigationButton onClick={goToEmail} />
      <Header />
      <StartHealing />
      <StopBurningMoney />
      <PersonalSummary />
      <Methods />
      <WithoutSideEffects />
      <HowDoesItWork />
      <PersonalAssistant />
      <MediaProof />
      <ZeroSmokesPros />
      <Author onClick={goToEmail} />
    </>
  );
};

export default Summary;
