import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  Box,
  Caption,
  Container,
  ContainerSmall,
  ContentFrameSmall,
  FlexWrapper,
  GridWrapper,
  H1,
  H3,
  H4,
  H5,
  Regular,
  Small,
  TextWrapper,
} from '@kilornd/modules-wrapper/es/components';

import {
  tablet,
  useQuery,
} from '@kilornd/modules-wrapper/es/styles/breakpoints';

import { Image, SectionWrapper, Svg } from 'components';
import { convertHexToRGB, error, textBlack, white } from 'styles/colors';

import {
  biologicalAgeSelector,
  smokingPeriodSelector,
} from 'state/user/selectors';

export const Header: React.FC = () => {
  const { isMobile, isTablet } = useQuery();
  const biologicalAge = useSelector(biologicalAgeSelector);
  const smokingPeriod = useSelector(smokingPeriodSelector);

  return (
    <SectionWrapperStyled>
      <Container>
        <H1 margin="0 0 1rem" textAlign={!isTablet && 'center'}>
          Quit smoking easily by rewiring your brain
        </H1>
        <Regular
          margin={isTablet ? '0 0 2rem' : '0 0 3rem'}
          textAlign={!isTablet && 'center'}
        >
          Learn how quitting will help your health and save you money. No huge
          withdrawals or weight gain.
        </Regular>
        <ContainerSmallStyled>
          <GridWrapper columns={isMobile ? 1 : 2}>
            <ContentFrameSmall>
              <H5 margin="0 0 0.5rem" textAlign={!isMobile && 'center'}>
                FEEL LESS CRAVINGS IN JUST ONE WEEK
              </H5>
              <Small margin="0 0 1rem">
                The average intensity of cravings will decrease rapidly as you
                learn to avoid your typical triggers.
              </Small>
              <Image maxHeight="14.125rem" src="cravings_chart" />
            </ContentFrameSmall>
            <ContentFrameSmall>
              <H5 margin="0 0 1rem" textAlign={!isMobile && 'center'}>
                STOP BURNING YOUR HEALTH
              </H5>
              <BoxStyled>
                <H4 margin="0 0 1rem">
                  Your biological age is higher due to smoking
                </H4>
                <FlexWrapper gridGap="1rem" margin="0 0 1rem">
                  <Svg height="3rem" src="biological_age" width="3rem" />
                  <Box>
                    <H3>{biologicalAge && biologicalAge} years</H3>
                    <Small color={textBlack}>
                      Slightly higher than your actual age
                    </Small>
                  </Box>
                </FlexWrapper>
                <Box
                  background={convertHexToRGB(error, 0.16)}
                  borderRadius="0.5rem"
                  padding="0.5rem"
                >
                  <Caption>
                    <TextWrapper fontWeight={700}>{smokingPeriod} </TextWrapper>
                    of smoking have made a series of negative changes to your
                    body. By quitting now, you’ll improve the quality of your
                    life for years to come.
                  </Caption>
                </Box>
              </BoxStyled>
            </ContentFrameSmall>
          </GridWrapper>
        </ContainerSmallStyled>
      </Container>
    </SectionWrapperStyled>
  );
};

const SectionWrapperStyled = styled(SectionWrapper)`
  @media ${tablet} {
    margin: 1rem 0;
  }
`;

const ContainerSmallStyled = styled(ContainerSmall)`
  padding: 0;
`;

const BoxStyled = styled(Box).attrs({
  padding: '1.5rem',
  background: `${white}`,
  borderRadius: '1rem',
  boxShadow: '0 0.5rem 1.5rem rgba(0, 0, 0, 0.04)',
})``;
