import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'gatsby';

import {
  Box,
  Caption,
  ContentFrameSmall,
  FlexWrapper,
  GridWrapper,
  H5,
} from '@kilornd/modules-wrapper/es/components';
import { useQuery } from '@kilornd/modules-wrapper/es/styles/breakpoints';

import { Image, PrimaryButton, SectionWrapper } from 'components';

import { conernArgumentsSelector } from 'state/user/selectors';

const SIDE_EFFECTS_ARGUMENTS = [
  {
    id: 1,
    key: 'miss_socializing',
    icon: 'socializing',
    title: 'Don’t miss out on socializing',
    subtitle: 'Explore ways how to build relationships without smoking.',
  },
  {
    id: 2,
    key: 'withdrawals',
    icon: 'laugh',
    title: 'No exhausting withdrawals',
    subtitle:
      'Remove the triggers and decrease the intensity of withdrawal symptoms.',
  },
  {
    id: 3,
    key: 'gain_weight',
    icon: 'no_weight_gain',
    title: 'No weight gain',
    subtitle:
      'Find out how to fight food cravings before they become a problem.',
  },
  {
    id: 4,
    key: 'stressed_moody',
    icon: 'meditate',
    title: 'No stress and mood swings',
    subtitle:
      'Learn how to decrease the urge naturally and enjoy freedom without wishing to come back.',
  },
  {
    id: 5,
    key: 'fail_to_quit',
    icon: 'results',
    title: 'Get long-lasting results',
    subtitle:
      'Change your life by changing your mindset and understanding your triggers.',
  },
  {
    id: 6,
    key: 'lose_enjoyment_of_smoking',
    icon: 'enjoy',
    title: 'More enjoyment guaranteed',
    subtitle: 'Feel how your smell, taste, and energy levels recover.',
  },
];

//NOTE: icons are saved as png, because of figma and designer styling bug

export const WithoutSideEffects: React.FC = () => {
  const { isTablet } = useQuery();
  const concernArguments = useSelector(conernArgumentsSelector);

  return (
    <SectionWrapper>
      <ContentFrameSmall padding="0 1rem">
        <H5 margin="0 0 1rem" textAlign={!isTablet && 'center'}>
          QUIT SMOKING WITHOUT SIDE EFFECTS
        </H5>
        <GridWrapper
          columns={1}
          gridGap="1rem"
          margin={isTablet ? '0 0 2.5rem' : '0 0 4rem'}
        >
          {SIDE_EFFECTS_ARGUMENTS.map(({ id, icon, key, title, subtitle }) => {
            if (
              concernArguments?.first === key ||
              concernArguments?.second === key ||
              concernArguments?.third === key
            ) {
              return (
                <FlexWrapper key={id} alignItems="flex-start">
                  <Box>
                    <Image width="2.5rem" height="2.5rem" src={icon} />
                  </Box>
                  <Box margin="0 0 0 1rem">
                    <H5 fontWeight={700}>{title}</H5>
                    <Caption>{subtitle}</Caption>
                  </Box>
                </FlexWrapper>
              );
            }
          })}
        </GridWrapper>
        <Link to="/email">
          <PrimaryButton
            id="summary_side_effects_button"
            data-testid="summary_side_effects_button"
          >
            Start now
          </PrimaryButton>
        </Link>
      </ContentFrameSmall>
    </SectionWrapper>
  );
};
