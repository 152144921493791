import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import {
  ContainerSmall,
  FlexWrapper,
  Small,
} from '@kilornd/modules-wrapper/es/components';
import {
  mobile,
  useQuery,
} from '@kilornd/modules-wrapper/es/styles/breakpoints';

import { Image, PrimaryButton, SectionWrapper } from 'components';

export const MediaProof: React.FC = () => {
  const { isTablet } = useQuery();
  return (
    <SectionWrapper>
      <ContainerSmall>
        <Small margin="0 0 2rem" textAlign={isTablet ? 'left' : 'center'}>
          The importance of choosing an effective quitting strategy featured in:
        </Small>
        <FlexWrapperStyled
          justifyContent="space-around"
          alignItems="center"
          margin={isTablet ? '0 0 2.5rem' : '0 0 4rem'}
        >
          <LogoWrapper>
            <Logo src="healthline_logo" height="1.5rem" />
          </LogoWrapper>
          <LogoWrapper>
            <Logo src="bi_logo" height="2rem" />
          </LogoWrapper>
          <LogoWrapper>
            <Logo src="nyt_logo" height="1.5rem" />
          </LogoWrapper>
          <LogoWrapper>
            <Logo src="bbc_logo" height="1.5rem" />
          </LogoWrapper>
        </FlexWrapperStyled>
        <Link to="/email">
          <PrimaryButton
            id="summary__media_button"
            data-testid="summary__media_button"
            maxWidth="22rem"
            margin="0 auto"
          >
            Start now
          </PrimaryButton>
        </Link>
      </ContainerSmall>
    </SectionWrapper>
  );
};

const FlexWrapperStyled = styled(FlexWrapper)`
  flex-wrap: wrap;
`;

const LogoWrapper = styled.div`
  padding: 1.25rem 1.25rem 0;

  @media ${mobile} {
    padding: 1.25rem 0.5rem 0;
  }
`;

const Logo = styled(Image)`
  width: auto;
  height: ${props => props.height || 'auto'};
`;
