import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import {
  ContainerSmall,
  FlexWrapper,
  GridWrapper,
  H1,
  H5,
  Small,
  TextWrapper,
} from '@kilornd/modules-wrapper/es/components';

import { useQuery } from '@kilornd/modules-wrapper/es/styles/breakpoints';

import { SectionWrapper } from 'components';
import { blue, green, yellow } from 'styles/colors';

import { smokingTriggersSelector } from 'state/user/selectors';

const METHODS = [
  {
    id: 1,
    key: 'emotional',
    amount: 56,
    text: 'methods how to deal with emotions that trigger smoking',
    color: yellow,
  },
  {
    id: 2,
    key: 'habit',
    amount: 89,
    text: 'methods how to eliminate smoking from your daily routine',
    color: blue,
  },
  {
    id: 3,
    key: 'social',
    amount: 71,
    text: 'methods how to remain smoke-free in social events without feeling like you’re missing out',
    color: green,
  },
  {
    id: 4,
    key: 'withdrawal',
    amount: 32,
    text: 'methods how to deal with nicotine withdrawal and remove the urge to smoke',
    color: yellow,
  },
];

export const Methods: React.FC = () => {
  const { isTablet } = useQuery();
  const smokingTriggers = useSelector(smokingTriggersSelector);

  return (
    <SectionWrapper>
      <ContainerSmall>
        <H5 margin="0 0 2rem" textAlign={!isTablet && 'center'}>
          BASED ON YOUR ANSWERS, WE FOUND:
        </H5>
        <GridWrapper columns={isTablet ? 1 : 2} margin="0 0 1rem">
          {METHODS.map(({ id, key, color, amount, text }) => {
            if (
              smokingTriggers?.trigger_1 === key ||
              smokingTriggers?.trigger_2 === key
            ) {
              return (
                <ContentFrameSmallStyled key={id} borderColor={color}>
                  <H1
                    as="h2"
                    color={color}
                    fontWeight={700}
                    margin="0 1.5rem 0 0"
                  >
                    {amount}
                  </H1>
                  <Small>{text}</Small>
                </ContentFrameSmallStyled>
              );
            }
          })}
        </GridWrapper>
        <Small textAlign={!isTablet && 'center'}>
          ... and{' '}
          <TextWrapper fontWeight={700}>500+ proven methods</TextWrapper> for
          other triggers and cravings
        </Small>
      </ContainerSmall>
    </SectionWrapper>
  );
};

const ContentFrameSmallStyled = styled(FlexWrapper).attrs({
  alignItems: 'center',
  padding: '1.5rem',
  borderRadius: '0.5rem',
  boxShadow: '0 0.5rem 1.5rem rgba(0, 0, 0, 0.04)',
})<{ borderColor: string }>`
  border: ${({ borderColor }) => `0.125rem dashed ${borderColor}`};
`;
