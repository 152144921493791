import React from 'react';
import styled from 'styled-components';

import {
  Caption,
  Container,
  GridWrapper,
  H3,
  H5,
  Small,
} from '@kilornd/modules-wrapper/es/components';
import {
  mobile,
  useQuery,
} from '@kilornd/modules-wrapper/es/styles/breakpoints';
import { Image, SectionWrapper, Slider } from 'components';

import { white } from 'styles/colors';

const LIST = [
  {
    icon: 'how_it_works_1',
    text: 'Get to know your triggers and behavior patterns.',
  },
  {
    icon: 'how_it_works_2',
    text: 'Train your mind to notice and change the thoughts and beliefs that encourage smoking.',
  },
  {
    icon: 'how_it_works_3',
    text: 'Learn proven techniques that help to change habits and remove the desire to smoke.',
  },
  {
    icon: 'how_it_works_4',
    text: 'Change your relationship with smoking and enjoy a zero-smoke life.',
  },
];

export const HowDoesItWork: React.FC = () => {
  const { isMobile } = useQuery();

  const sliderSettings = {
    arrows: false,
    infinite: true,
    slidesToShow: 1.1,
  };

  const stepList = LIST.map(({ text, icon }, index) => (
    <Card key={text} textAlign="center" padding="2rem">
      <Image src={icon} margin="0 auto" />
      <Small margin="1rem 0 0.5rem" fontWeight={700}>
        {index + 1}
      </Small>
      <Small>{text}</Small>
    </Card>
  ));

  return (
    <SectionWrapper>
      <Container margin={isMobile ? '0 auto' : '0 auto 5rem'}>
        <H5 margin="0 0 0.5rem" textAlign={isMobile ? 'left' : 'center'}>
          HOW DOES THE ZEROSMOKES PROGRAM WORK?
        </H5>
        <Small
          margin={isMobile ? '0 0 1rem' : '0 0 2rem'}
          textAlign={isMobile ? 'left' : 'center'}
        >
          This program will consist of 4 main parts:
        </Small>
        {isMobile ? (
          <SliderWrapper>
            <Slider sliderSettings={sliderSettings}>{stepList}</Slider>
          </SliderWrapper>
        ) : (
          <GridWrapper columns={4} gap="1.5rem" margin="0 0 3rem">
            {stepList}
          </GridWrapper>
        )}
      </Container>
    </SectionWrapper>
  );
};

const Card = styled.div<{ textAlign?: string; padding?: string }>`
  height: 100%;
  padding: ${({ padding }) => padding ?? '1rem'};
  background-color: ${white};
  border-radius: 0.5rem;
  text-align: ${({ textAlign }) => textAlign ?? 'left'};

  @media ${mobile} {
    max-width: 95%;
    margin: 0 1.5rem;
  }
`;

const SliderWrapper = styled.div`
  .slick-list {
    margin: 0 0 -3rem;
    padding: 0 0 3rem;
  }
  .slick-track {
    display: flex;
  }
  .slick-slide {
    height: inherit;
  }
  .slick-slide > div {
    height: 100%;
  }

  @media ${mobile} {
    margin: 0 0 2rem;
  }
`;
