import React from 'react';
import { RouteComponentProps } from '@reach/router';
import styled from 'styled-components';
import { Container, FlexWrapper } from '@kilornd/modules-wrapper/es/components';
import {
  mobile,
  useQuery,
} from '@kilornd/modules-wrapper/es/styles/breakpoints';

import { Logo, PrimaryButton } from 'components';
import { black, convertHexToRGB, white } from 'styles/colors';

interface Props {
  onClick?: () => void;
}

export const NavigationButton: React.FC<RouteComponentProps & Props> = ({
  onClick,
}) => {
  const { isMobile } = useQuery();

  return (
    <LogoWrapper>
      <Container>
        <FlexWrapper justifyContent="space-between" alignItems="center">
          <Logo />
          <PrimaryButtonStyled
            onClick={onClick}
            size={isMobile ? 'extra-small' : 'small'}
            width="auto"
          >
            Start now
          </PrimaryButtonStyled>
        </FlexWrapper>
      </Container>
    </LogoWrapper>
  );
};

const LogoWrapper = styled.div`
  position: sticky;
  top: 0;
  padding: 1rem 0;
  background-color: ${white};
  box-shadow: 0 0.5rem 1.5rem ${convertHexToRGB(black, 0.04)};
  z-index: 9;

  @media ${mobile} {
    padding: 0.75rem 0;
  }
`;

const PrimaryButtonStyled = styled(PrimaryButton)`
  border-radius: 0.5rem;
`;
