import React from 'react';
import { Element } from 'react-scroll';

import {
  ContainerSmall,
  ContentFrameSmall,
  FlexWrapper,
  H3,
  Regular,
  TextWrapper,
} from '@kilornd/modules-wrapper/es/components';
import { useQuery } from '@kilornd/modules-wrapper/es/styles/breakpoints';

import { Image, PrimaryButton, SectionWrapper } from 'components';

interface Props {
  onClick: () => void;
}

export const Author: React.FC<Props> = ({ onClick }) => {
  const { isTablet } = useQuery();

  return (
    <SectionWrapper>
      <ContainerSmall>
        <H3
          margin={isTablet ? '0 0 1rem' : '0 0 2rem'}
          textAlign={!isTablet && 'center'}
        >
          Our story
        </H3>
        <FlexWrapper
          margin="0 0 3rem"
          flexDirection={isTablet && 'column-reverse'}
          justifyContent={!isTablet && 'space-between'}
        >
          <ContentFrameSmall margin="0 2.5rem 0 0 ">
            <Regular margin="0 0 1rem">
              While studying psychology, I quickly fell into the &quot;work
              hard, play hard&quot; style of living, trying to juggle my time
              between studying and work. Without realizing it, I started smoking
              during work breaks, in-between classes, and almost constantly
              while studying. I have tried everything over those 15 years, from
              tobacco to e-cigarettes. Smoking became a habit, and in some sense
              – a job.
              <br />
              <br />
              When my son was born, I realized that I already had all the tools
              to quit. I just needed to make a clear plan. Based on the
              principles of cognitive behavioral therapy, I created a program
              for myself. I gave up smoking effortlessly, avoided withdrawal
              symptoms, and never craved to start smoking again. I created
              programs for my friends, family, and others to help them quit,
              too. 5 years ago, I launched ZeroSmokes to share my quitting plans
              and techniques with smokers worldwide and help them get their life
              back.
            </Regular>
            <FlexWrapper justifyContent="space-between">
              <TextWrapper>
                <Regular fontWeight="bold">James,</Regular>
                <Regular fontWeight="bold">Founder of ZeroSmokes®</Regular>
              </TextWrapper>
              <Image maxWidth="9.3rem" src="signature" />
            </FlexWrapper>
          </ContentFrameSmall>
          <ContentFrameSmall>
            <Image margin={isTablet ? '0 0 1rem' : '0'} src="author" />
          </ContentFrameSmall>
        </FlexWrapper>
        <Element name="page-end">
          <PrimaryButton
            onClick={onClick}
            id="summary__start-now-page-end"
            data-testid="summary__start-now-button-page-end"
            maxWidth="22rem"
            margin="0 auto"
          >
            Start now
          </PrimaryButton>
        </Element>
      </ContainerSmall>
    </SectionWrapper>
  );
};
