import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  Caption,
  ContainerSmall,
  ContentFrameSmall,
  GridWrapper,
  H5,
  Regular,
} from '@kilornd/modules-wrapper/es/components';

import { useQuery } from '@kilornd/modules-wrapper/es/styles/breakpoints';

import { SectionWrapper } from 'components';
import { white } from 'styles/colors';

import { CravingsProgressBar } from '../elements/CravingsProgressBar';
import { smokingTriggersSelector } from 'state/user/selectors';

const SMOKING_TRIGGERS = [
  {
    id: 1,
    key: 'emotional',
    title: 'Emotional triggers',
    desc: 'You are likely to smoke more when you feel intense emotions such as stress, excitement, boredom, or loneliness.',
  },
  {
    id: 2,
    key: 'habit',
    title: 'Habit triggers',
    desc: 'You may connect certain activities, such as drinking alcohol, finishing a meal, to smoking. That might give you an additional trigger to smoke.',
  },
  {
    id: 3,
    key: 'social',
    title: 'Social triggers',
    desc: 'You are likely to smoke more on occasions that include other people, such as going to a bar, being at a party, or celebrating a big event.',
  },
  {
    id: 4,
    key: 'withdrawal',
    title: 'Withdrawal triggers',
    desc: 'Most of your cravings might came from nicotine withdrawal and be triggered by the taste, smell, or handling of cigarettes, matches, or vapers.',
  },
];

export const PersonalSummary: React.FC = () => {
  const { isTablet } = useQuery();
  const smokingTriggers = useSelector(smokingTriggersSelector);

  const triggersBlock = (id: number, title: string, desc: string) => (
    <ContentFrameSmallStyled key={id} margin={!isTablet && '0 1rem 0 0'}>
      <Regular fontWeight={700} margin="0 0 4rem" textAlign="center">
        {title}
      </Regular>
      <CravingsProgressBar />
      <Caption textAlign="center">{desc}</Caption>
    </ContentFrameSmallStyled>
  );

  return (
    <SectionWrapper>
      <ContainerSmall>
        <H5 margin="0 0 2rem" textAlign={!isTablet && 'center'}>
          UNDERSTAND YOUR TRIGGERS AND FIND OUT HOW TO FIGHT THEM
        </H5>
        <GridWrapper
          columns={isTablet ? 1 : 2}
          margin={isTablet ? '0 0 2.5rem' : '0 0 5rem'}
        >
          {SMOKING_TRIGGERS.map(({ id, key, title, desc }) => {
            if (
              smokingTriggers?.trigger_1 === key ||
              smokingTriggers?.trigger_2 === key
            ) {
              return triggersBlock(id, title, desc);
            } else if (
              smokingTriggers?.trigger_1 === 'none' &&
              (key === 'habit' || key === 'withdrawal')
            ) {
              return triggersBlock(id, title, desc);
            }
          })}
        </GridWrapper>
      </ContainerSmall>
    </SectionWrapper>
  );
};

const ContentFrameSmallStyled = styled(ContentFrameSmall).attrs({
  padding: '2rem 1.5rem',
  background: `${white}`,
  borderRadius: '1rem',
  boxShadow: '0 0.5rem 1.5rem rgba(0, 0, 0, 0.04)',
})``;
